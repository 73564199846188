.badge {
  font-size: 80%;

  &.badge-outline {
    border: 1px solid;
    background: #fff;

    &.badge-default {
      border-color: #505558;
      color: #393e42;
    }

    &.badge-primary {
      border-color: #0275d8;
      color: #0275d8;
    }

    &.badge-success {
      border-color: #5cb85c;
      color: #5cb85c;
    }

    &.badge-info {
      border-color: #5bc0de;
      color: #5bc0de;
    }

    &.badge-warning {
      border-color: #f0ad4e;
      color: #f0ad4e;
    }

    &.badge-danger {
      border-color: #d9534f;
      color: #d9534f;
    }
  }
}
