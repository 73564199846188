@mixin font-smoothing {
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin font-smoothing-none {
	-webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

@mixin transition($transitions...) {
	-webkit-transition: $transitions;
    -moz-transition: $transitions;
    -ms-transition: $transitions;
    -o-transition: $transitions;
    transition: $transitions;
}

@mixin transitionDelay($delay) {
	-webkit-transition-delay: $delay;
	-moz-transition-delay: $delay;
	-o-transition-delay: $delay;
	transition-delay: $delay;
}

@mixin transitionDuration($durations) {
	-webkit-transition-duration: $durations;
	-moz-transition-duration: $durations;
	-o-transition-duration: $durations;
	transition-duration: $durations;
}

@mixin transitionProperty($property) {
	-webkit-transition-property: $property;
	-moz-transition-property: $property;
	-o-transition-property: $property;
	transition-property: $property;
}

@mixin transitionTimingFunction($values) {
	-webkit-transition-timing-function: $values;
	-moz-transition-timing-function: $values;
	-o-transition-timing-function: $values;
	transition-timing-function: $values;
}

/* this mixin makes possible to add transition to a specific vendor, example: */
/* -webkit-transition: -webkit-transform 0.5s linear; */
@mixin transitionPrefix($property, $values) {
	-webkit-transition: -webkit-#{$property} #{$values};
    -moz-transition: -moz-#{$property} #{$values};
    -ms-transition: -ms-#{$property} #{$values};
    -o-transition: -o-#{$property} #{$values};
    transition: #{$property} #{$values};	
}

@mixin background-gradient($from, $to) {
	background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, $from), color-stop(100%,  $to));
	background: -webkit-linear-gradient($from, $to);
	background: -moz-linear-gradient($from, $to);
	background: -o-linear-gradient($from, $to);
	background: linear-gradient($from, $to);
}

@mixin transform($transforms) {
	-moz-transform: $transforms;
	-o-transform: $transforms;
	-ms-transform: $transforms;
	-webkit-transform: $transforms;
	transform: $transforms;
}

@mixin transformOrigin($transformOrigins) {
	-moz-transform-origin: $transformOrigins;
	-o-transform-origin: $transformOrigins;
	-ms-transform-origin: $transformOrigins;
	-webkit-transform-origin: $transformOrigins;
	transform-origin: $transformOrigins;
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}

@mixin animation($animations) {
	-webkit-animation: $animations;
	-moz-animation: $animations;
	-o-animation: $animations;
	-ms-animation: $animations;
	animation: $animations;
}

@mixin animationDuration($value) {
	-webkit-animation-duration: $value;
	-moz-animation-duration: $value;
	-o-animation-duration: $value;
	-ms-animation-duration: $value;
	animation-duration: $value;
}

@mixin animationDelay($value) {
	-webkit-animation-delay: $value;
	-moz-animation-delay: $value;
	-o-animation-delay: $value;
	-ms-animation-delay: $value;
	animation-delay: $value;
}

@mixin animationTimingFunction($value) {
	-webkit-animation-timing-function: $value;
	-moz-animation-timing-function: $value;
	-o-animation-timing-function: $value;
	-ms-animation-timing-function: $value;
	animation-timing-function: $value;
}

@mixin keyFrame($name) {
	@-webkit-keyframes #{$name}{
		@content
	}
	@-moz-keyframes #{$name}{
		@content
	}
	@-o-keyframes #{$name}{
		@content
	}
	@keyframes #{$name}{
		@content
	}
}

